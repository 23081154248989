/**
 * Utilities
 * Some good to have functions/helpers that works without jquery.
 * @see {@link http://youmightnotneedjquery.com/}
 * @version 3.0.0
 */

/**
 * Get the browser to rerender.
 * @param {String} browser - A class-name from the html-tag that defines a browser (eg 'lt-ie9', 'lt-ie8').
 * @public
 */
export const rerenderElement = browser => {
	if (
		browser === undefined ||
		(browser !== undefined &&
			document.documentElement.classList.contains(browser))
	) {
		document.body.className = document.body.className;
	}
};

/**
 * wraps a DOM node with another node.
 * @param {Object} el - The DOM node that should be wrapped.
 * @param {Object} wrapper - A DOM node that wraps the other node.
 * @public
 */
export const wrap = (el, wrapper) => {
	el.parentNode.insertBefore(wrapper, el);
	wrapper.appendChild(el);
};

/**
 * Triggers an event for an element.
 * @param {Object} el - A DOM node.
 * @param {String} eventName - An event name.
 * @public
 */
export const triggerEvent = (el, eventName) => {
	let event; // The custom event that will be created

	if (eventName === 'submit') {
		el.submit();
		return false;
	}

	if (document.createEvent) {
		event = document.createEvent('HTMLEvents');
		event.initEvent(eventName, true, true);
	} else {
		event = document.createEventObject();
		event.eventType = eventName;
	}

	event.eventName = eventName;

	if (document.createEvent) {
		el.dispatchEvent(event);
	} else {
		el.fireEvent('on' + event.eventType, event);
	}
};

/**
 * Removes a DOM node.
 * @param {Object} el - A DOM node.
 * @public
 */
export const remove = el => {
	if (el.remove) {
		el.remove();
	} else {
		el.parentNode.removeChild(el);
	}
};

/**
 * Extends/mixes two or more objects.
 * @param {...Object} Two of more objects.
 * @public
 */
export function extend() {
	for (let i = 1; i < arguments.length; i++) {
		for (let key in arguments[i]) {
			if (arguments[i].hasOwnProperty(key)) {
				arguments[0][key] = arguments[i][key];
			}
		}
	}
	return arguments[0];
}

/**
 * Removes tags from an html string, except the tags defined in the allow-parameter.
 * @param {String} html - A string of html.
 * @param {String} allowed - A string of tags to be allowed (e.g. '<ul><li>').
 * @public
 */
export const stripTags = (html, allowed) => {
	allowed = (
		((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
	).join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
	const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
		commentTags = /<!--[\s\S]*?-->/gi;

	return html.replace(commentTags, '').replace(tags, function($0, $1) {
		return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
	});
};

/**
 * Detects if an element is in the viewport.
 * @param {Object} el - A DOM node.
 * @see {@link http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433}
 * @public
 */
export const isElementInViewport = el => {
	let rect = el.getBoundingClientRect();

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <=
			(window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
};

/**
 * Detects if browser has support for the History API.
 * @public
 */
export const hasHistorySupport = () => {
	return !!(window.history && history.pushState);
};

/**
 * Returns a timestamp
 * @public
 */
export const getTimestamp = () => {
	if (!Date.now) {
		return new Date().getTime();
	} else {
		return Date.now();
	}
};

/**
 * Parse a form and creates an object with all the keys and values.
 * @param {Object} form - A FORM DOM node.
 * @see {@link https://plainjs.com/javascript/ajax/serialize-form-data-into-an-array-46/}
 * @public
 */
export const formToArray = form => {
	let field,
		l,
		s = [];

	if (typeof form == 'object' && form.nodeName == 'FORM') {
		let len = form.elements.length;

		for (let i = 0; i < len; i++) {
			field = form.elements[i];
			if (
				field.name &&
				!field.disabled &&
				field.type != 'file' &&
				field.type != 'reset' &&
				field.type != 'submit' &&
				field.type != 'button'
			) {
				if (field.type == 'select-multiple') {
					l = form.elements[i].options.length;
					for (let j = 0; j < l; j++) {
						if (field.options[j].selected) {
							s[s.length] = { name: field.name, value: field.options[j].value };
						}
					}
				} else if (
					(field.type != 'checkbox' && field.type != 'radio') ||
					field.checked
				) {
					s[s.length] = { name: field.name, value: field.value };
				}
			}
		}
	}

	return s;
};

/**
 * Takes a form and creates a query-string from it's values.
 * @param {Object} form - A FORM DOM node.
 * @see {@link module:utilities.formToArray}
 * @public
 */
export const formToQuery = form => {
	let data = formToArray(form),
		query = [];

	data.forEach(obj => {
		query.push(
			encodeURIComponent(obj.name) + '=' + encodeURIComponent(obj.value)
		);
	});

	return query.join('&');
};

/**
 * Adds/updates a url query parameter
 * @param {String} url - The url.
 * @param {String} param - The parameter name.
 * @param {String} paramVal - The parameter value.
 * @public
 */
export const updateURLParameter = (url, param, paramVal) => {
	let newAdditionalURL = '',
		tempArray = url.split('?'),
		baseURL = tempArray[0],
		additionalURL = tempArray[1],
		temp = '';

	if (additionalURL) {
		tempArray = additionalURL.split('&');
		for (let i = 0; i < tempArray.length; i++) {
			if (tempArray[i].split('=')[0] != param) {
				newAdditionalURL += temp + tempArray[i];
				temp = '&';
			}
		}
	}

	let rows_txt = temp + '' + param + '=' + paramVal;
	return baseURL + '?' + newAdditionalURL + rows_txt;
};

/**
 * Returns the value of an object attribute based of the attribute "path" as an string.
 * @param {Object} o - A object.
 * @param {String} s - A string.
 * @public
 */
export const objectByString = (o, s) => {
	s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
	s = s.replace(/^\./, ''); // strip a leading dot
	let a = s.split('.');
	for (let i = 0, n = a.length; i < n; ++i) {
		let k = a[i];
		if (k in o) {
			o = o[k];
		} else {
			return;
		}
	}
	return o;
};
