/**
 * main.js
 * Here we handle polyfills and bootstraping, then we start app. (the actual starting point is in app.js).
 * @see {@link http://usejsdoc.org} for information about comments standard used.
 * @version 3.0.0
 */

import App from './app';
import {
	polyfillForEachNodelist,
	polyfillEs6Promise,
	domParserPolyfill,
	polyfillRequestAnimationFrame,
	polyfillClassList,
} from './components/polyfill/polyfill';

// *** NOT SUPPORTED BY IE8 ***
// element.addEventListener()
// event.preventDefault()
// function.bind()

// Needed for IE9 support.
polyfillForEachNodelist();

// Needed for IE9 support.
domParserPolyfill();

// Needed for IE9 support. (No scripts in the boilerplate uses RequestAnimationFrame, but it can be good to have if needed).
polyfillRequestAnimationFrame();

// Needed for IE11 suuport.
polyfillEs6Promise();

// Needed for IE8/IE9 support.
polyfillClassList();

// Start the app.
new App();
