import ToggleLink from './components/toggleLink/toggleLink';
import Megadrop from './components/megadrop/megadrop';
import MobileMenu from './components/mobileMenu/mobileMenu';
import TabUsability from './components/tabUsability/tabUsability';
import EpiserverForm from './components/episerverForm/episerverForm';
import Flickity from 'flickity';
import 'flickity-fade';
// import 'google-maps';
import { wrap } from './components/utilities/utilities';

/**
 * App
 * This starting point for this application.
 * @version 3.0.0
 * @exports App
 */
export default class App {
	constructor() {
		// Init menu
		new Megadrop();

		// Init mobile menu
		new MobileMenu(document.querySelector('[data-type="mobileMenu"]'), {
			ajaxPath: mainConfig.menuAjaxPath,
			ajaxActivePane: mainConfig.menuAjaxActivePane,
			searchField: true,
			searchMethod: mainConfig.searchMethod,
			searchPage: mainConfig.searchPage,
			searchParameter: mainConfig.searchParameter,
			searchPlaceholder: mainConfig.searchPlaceholder,
		});

		// Init toggle link
		new ToggleLink();

		// Init tabUsability
		new TabUsability();

		// Init table wrap for mobile scrolling of tables in rte.
		this._initRteTableWrap();

		// Init ajaxForm
		this._initAjaxForm();

		// Init episerverForms
		this._initEpiserverForm();

		// Demo plugins (for demo purpose only, remove this when you build a new web).
		this._loadPluginsDemonstration();

		// Init flickity, the carousel on the front page.
		this._initFlickity();

		// Init google maps, not running until customers creates an account and sends us the api-key.
		// this._initMap();
	}

	/**
	 * Function that initiates the ajax-form functionality.
	 * @private
	 */
	_initAjaxForm() {
		let els = document.querySelectorAll('[data-type="ajaxForm"]');

		if (els.length > 0) {
			import(
				/* webpackChunkName: "ajaxForm" */ './components/ajaxForm/ajaxForm'
			).then(AjaxForm => {
				for (let i = 0; i < els.length; i++) {
					let el = els[i];

					if (el.getAttribute('data-ajaxform-initiated') === null) {
						new AjaxForm.default(el);
						el.setAttribute('data-ajaxform-initiated', true);
					}
				}
			});
		}
	}

	/**
	 * Function that initiates the episerverform functionality.
	 * @private
	 */
	_initEpiserverForm() {
		let els = document.querySelectorAll('[data-type="episerverForm"]');

		if (els.length > 0) {
			for (let i = 0; i < els.length; i++) {
				let el = els[i];

				if (el.getAttribute('data-episerverform-initiated') === null) {
					new EpiserverForm(el);
					el.setAttribute('data-episerverform-initiated', true);
				}
			}
		}
	}

	/**
	 * Function that wraps all tables in rte with a div to enable the scroll-feature in mobile.
	 * @private
	 */
	_initRteTableWrap() {
		let els = document.querySelectorAll('.rte table');

		els.forEach(el => {
			let wrapper = document.createElement('div');
			wrapper.classList.add('table-scrollable');
			wrap(el, wrapper);
		});
	}

	/**
	 * Loads all plugins in the framework to demonstrate them (this should be removed on "real" webprojects).
	 * @todo Remove this function from "real" projects.
	 * @private
	 */
	_loadPluginsDemonstration() {
		if (document.querySelectorAll('[data-type="share"]').length > 0) {
			import(/* webpackChunkName: "share" */ './components/share/share').then(
				Share => {
					new Share.default();
				}
			);
		}

		if (document.querySelectorAll('[data-type="loadMore"]').length > 0) {
			import(
				/* webpackChunkName: "loadMore" */ './components/loadMore/loadMore'
			).then(LoadMore => {
				new LoadMore.default();
			});
		}

		if (document.querySelectorAll('[data-type="autosubmit"]').length > 0) {
			import(
				/* webpackChunkName: "autoSubmit" */ './components/autoSubmit/autoSubmit'
			).then(AutoSubmit => {
				new AutoSubmit.default();
			});
		}

		if (document.getElementById('autoembed-demo')) {
			import(
				/* webpackChunkName: "autoEmbed" */ './components/autoEmbed/autoEmbed'
			).then(AutoEmbed => {
				const testNode = document.getElementById('autoembed-demo');
				const input = testNode.innerHTML;
				const embed = new AutoEmbed.default();
				const htmlWithEmbeds = embed.parse(input);
				testNode.innerHTML = htmlWithEmbeds;
				embed.renderJsEmbeds();
			});
		}
	}

	/**
	 * Function that sets the flickity carousel thats located on the front page.
	 * @private
	 */
	_initFlickity() {
		var elem = document.querySelector('.carousel');

		if (elem) {
			new Flickity(elem, {
				// options
				cellAlign: 'left',
				contain: true,
				autoPlay: 3000,
				prevNextButtons: false,
				fade: true,
				pauseAutoPlayOnHover: true,
				// pageDots: false
			});
		}
	}

	// Spara denna ifall kund går över till Google Maps:
	// _initMap() {
	// 	// The location of Uluru
	// 	var uluru = { lat: -25.344, lng: 131.036 };
	// 	// The map, centered at Uluru
	// 	var map = new google.maps.Map(
	// 		document.getElementById('map'), { zoom: 4, center: uluru });
	// 	// The marker, positioned at Uluru
	// 	var marker = new google.maps.Marker({ position: uluru, map: map });
	// }
}
