import Es6Promise from 'es6-promise';

/**
 * Polyfills
 * Some polyfills to support older browsers.
 * @version 3.0.0
 */

/**
 * Polyfill for forEach method on nodelists. (needed for IE9 support).
 * @public
 */
export const polyfillForEachNodelist = () => {
	if (window.NodeList && !NodeList.prototype.forEach) {
		NodeList.prototype.forEach = function(callback, argument) {
			argument = argument || window;
			for (var i = 0; i < this.length; i++) {
				callback.call(argument, this[i], i, this);
			}
		};
	}
};

/**
 * wraps a DOM node with another node. (needed for IE11 support).
 * @see {@link https://www.npmjs.com/package/es6-promise}
 * @public
 */
export const polyfillEs6Promise = () => {
	Es6Promise.polyfill();
	/*
		import('es6-promise').then((Es6Promise) => {
			Es6Promise.polyfill();
		});
	 */
};

/**
 * DOMParser HTML extension for other browsers. (needed for IE9 support).
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/DOMParser}
 * @public
 */
export const domParserPolyfill = () => {
	if (window.DOMParser !== undefined) {
		(function(DOMParser) {
			var proto = DOMParser.prototype,
				nativeParse = proto.parseFromString;

			// Firefox/Opera/IE throw errors on unsupported types
			try {
				// WebKit returns null on unsupported types
				if (new DOMParser().parseFromString('', 'text/html')) {
					// text/html parsing is natively supported
					return;
				}
			} catch (ex) { }

			proto.parseFromString = function(markup, type) {
				if (/^\s*text\/html\s*(?:;|$)/i.test(type)) {
					var doc = document.implementation.createHTMLDocument('');

					if (markup.toLowerCase().indexOf('<!doctype') > -1) {
						doc.documentElement.innerHTML = markup;
					} else {
						doc.body.innerHTML = markup;
					}

					return doc;
				} else {
					return nativeParse.apply(this, arguments);
				}
			};
		})(DOMParser);
	}
};

/**
 * Polyfill requestAnimationFrame.
 * @see {@link http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/}
 * @private
 */
export const polyfillRequestAnimationFrame = () => {
	var lastTime = 0,
		vendors = ['webkit', 'moz'];

	for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
		window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
		window.cancelAnimationFrame =
			window[vendors[x] + 'CancelAnimationFrame'] ||
			window[vendors[x] + 'CancelRequestAnimationFrame'];
	}

	if (!window.requestAnimationFrame) {
		window.requestAnimationFrame = function(callback) {
			var currTime = new Date().getTime();
			var timeToCall = Math.max(0, 16 - (currTime - lastTime));
			var id = window.setTimeout(function() {
				callback(currTime + timeToCall);
			}, timeToCall);
			lastTime = currTime + timeToCall;
			return id;
		};
	}

	if (!window.cancelAnimationFrame) {
		window.cancelAnimationFrame = function(id) {
			clearTimeout(id);
		};
	}
};

/**
 * Polyfill classList.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/classList/}
 * @private
 */
export const polyfillClassList = () => {
	// helpers
	var regExp = function(name) {
		return new RegExp('(^| )' + name + '( |$)');
	};
	var forEach = function(list, fn, scope) {
		for (var i = 0; i < list.length; i++) {
			fn.call(scope, list[i]);
		}
	};

	// Class list object with basic methods
	function ClassList(element) {
		this.element = element;
	}

	ClassList.prototype = {
		add: function() {
			forEach(
				arguments,
				function(name) {
					if (!this.contains(name)) {
						this.element.className +=
							this.element.className.length > 0 ? ' ' + name : name;
					}
				},
				this
			);
		},
		remove: function() {
			forEach(
				arguments,
				function(name) {
					this.element.className = this.element.className.replace(
						regExp(name),
						''
					);
				},
				this
			);
		},
		toggle: function(name) {
			return this.contains(name)
				? (this.remove(name), false)
				: (this.add(name), true);
		},
		contains: function(name) {
			return regExp(name).test(this.element.className);
		},
		// Bonus
		replace: function(oldName, newName) {
			this.remove(oldName), this.add(newName);
		},
	};

	// IE8/9, Safari
	if (!('classList' in Element.prototype)) {
		Object.defineProperty(Element.prototype, 'classList', {
			get: function() {
				return new ClassList(this);
			},
		});
	}

	// Replace() support for others
	if (window.DOMTokenList && DOMTokenList.prototype.replace == null) {
		DOMTokenList.prototype.replace = ClassList.prototype.replace;
	}
};
