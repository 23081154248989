import hoverIntent from '../../libs/hoverIntent';

/**
 * Megadrop
 * Functions for the megadrop.
 * @version 3.0.0
 * @exports Megadrop
 */
export default class Megadrop {
	/**
	 * Constructor
	 * @public
	 */
	constructor() {
		!Modernizr.touchevents ? this._initHoverIntent() : this._initTouchHover();

		this._initKeyboardNavigation();
	}

	/**
	 * Add megadrop hover function with the hoverIntent plugin.
	 * @private
	 */
	_initHoverIntent() {
		let els = document.querySelectorAll('.has-megadrop');

		els.forEach(el => {
			hoverIntent(
				el,
				function() {
					this.classList.add('megadrop--active');
				},
				function() {
					this.classList.remove('megadrop--active');
				}
			).options({
				timeout: 300,
				interval: 300,
			});
		});

		document.documentElement.classList.remove('megadrop-uninitiated'); // Removal of this class will disable megadrop display through css hovering.
	}

	/**
	 * Fixes so hover works on touch devices.
	 * @private
	 */
	_initTouchHover() {
		let els = document.querySelectorAll('.has-megadrop');

		els.forEach(el => {
			el.addEventListener('click', event => {
				if (
					!el.classList.contains('megadrop--active') ||
					(el.classList.contains('megadrop--active') &&
						event.target.tagName !== 'A')
				) {
					event.preventDefault();
				}

				let els = document.querySelectorAll('.has-megadrop');
				els.forEach(el => {
					el.classList.remove('megadrop--active');
				});

				event.target.parentNode.classList.add('megadrop--active');
			});
		});

		document.documentElement.classList.remove('megadrop-uninitiated'); // Removal of this class will disable megadrop display through css hovering.
	}

	/**
	 * Add keyboard tab navigation to megadrop.
	 * @private
	 */
	_initKeyboardNavigation() {
		let els = document.querySelectorAll('.has-megadrop');

		els.forEach(el => {
			el.addEventListener('keydown', event => {
				if (event.keyCode === 13) {
					if (!el.classList.contains('megadrop--active')) {
						event.preventDefault();
						el.classList.add('megadrop--active');
					}
				}
			});

			el.addEventListener(
				'focus',
				() => {
					if (el.getAttribute('data-focusOutTimer') !== undefined) {
						clearTimeout(el.getAttribute('data-focusOutTimer'));
					}
				},
				true
			);

			el.addEventListener(
				'blur',
				() => {
					el.setAttribute(
						'data-focusOutTimer',
						setTimeout(() => {
							el.classList.remove('megadrop--active');
						}, 50)
					);
				},
				true
			);
		});
	}
}
